<template>
  <div class="form-fieldgroup" :class='{collapsible: this.collapsible}'>
    <div class="form-fieldgroup-title">
        <span v-if='field.field_label'>{{field.field_label[this.$store.state.user.languageCode].value}}</span>
        <span v-else>{{field.field_name[this.$store.state.user.languageCode].value}}</span>
        <div v-if='collapsible && this.showControlls' class="collapseIconWrap" @click='collapse()'>
          <img class="collapseIcon" :class="{'expanded':!collapsed}" alt="" src="../assets/view-check/Nav_carrot.svg" />
        </div>
        <!--<div v-if='collapsible && this.collapsed && this.showControlls' class="collapseIconWrap" @click='collapse(false)'>
          <img class="collapseIcon" alt="" src="../assets/view-check/Nav_carrot.svg" />
        </div>
        <div v-if='collapsible && !this.collapsed && this.showControlls' class="collapseIconWrap" @click='collapse(true)'>
          <img class="collapseIcon expanded" alt="" src="../assets/view-check/Nav_carrot.svg" />
        </div>-->
    </div>
    <div class="sepperator"></div>
    <div class="groupCheck" v-if='collapsible'>
        <checkgroup 
            :parentCollapsed='collapsed'
            :formId='formId'
            :fieldId='field.form_field_id'
            :parentId='field.parent_id' 
            value='' 
            :collapseParent='this.collapse' 
            :scrollToNext='this.scrollToNextField' 
            :highlightField='this.setHighlightFields'
            :isPersonal='this.isPersonal'>
        </checkgroup>
    </div>
    <div ref="collapsible" class="form-field-wrapper" :style="[(this.collapsible && !this.collapsed) ? { height : height } : {}]">
      <div class="form-field" v-for='child in fields' :key="child.form_field_id">
        <FormFieldGroup
          :class="'level'+child.level"
          v-if="child.field_type.type === 'group'"
          :field="child"
          :formId='formId'
          :taskId='taskId'
          :collapsible="canCompleteChildren(child.can_complete_children)"
          :isPersonal='isPersonal'
        />
        <FormField
          v-else-if='child.skip !== true'
          :class="'level'+child.level"
          :field="child"
          :formId='formId'
          :taskId='taskId'
          :highlighted='highlightedFields.includes(child.form_field_id)'
          :isPersonal='isPersonal'
        />
      </div>
    </div>
    
  </div>
</template>

<script>
import FormField from "@/components/FormField.vue";
import CheckGroup from "@/components/FormFields/CheckGroup.vue";
//for browser that do not support windo.scrollTo -> behaviour:'smooth'
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

export default {
  name: 'FormFieldGroup',
  props: {
    field: {
      type: Object,
      default() {
        return {};
      },
    },
    formId: Number,
    taskId:Number,
    language: String,
    collapsible:Boolean,
    isPersonal:Boolean
  },
  data() {
    return {
      name: null,
      hovering: false,
      collapsed: false,
      height: 0,
      showControlls: true,
      highlightedFields:[],
    };
  },
  components: {
    'FormField':FormField,
    'checkgroup':CheckGroup,
  },
  created() {
    if (!this.field) return;
    if (this.field.field_name) this.name = this.field.field_name;
    
    
  },
  mounted(){
    if(this.collapsible){
      this.$refs['collapsible'].style.height = 'auto';
      this.height = getComputedStyle(this.$refs['collapsible']).height;
      this.$refs['collapsible'].style.height = 0;
      this.collapsed = true;
    }
  },
  methods: {
    //diese methode nur nötig da manchmal string statt bool ...
    canCompleteChildren(collapsible){
        if(typeof collapsible === 'string'){
            return (collapsible === 'true');
        }
        return collapsible;
    },
    collapse(value, showControlls){
      if(value !== undefined){
        this.collapsed = value;
      }else{
        this.collapsed = !this.collapsed;
      }
      if(showControlls !== undefined){
        this.showControlls = showControlls;
      }
    },
    setHighlightFields(ids, time){
        let defaultTime = 3000;
        if(time){
            defaultTime = time;
        }
        this.highlightedFields = ids;
        setTimeout(() => {
            this.highlightedFields = [];
        },defaultTime);
        
    },
    scrollToNextField(){
        //const el = this.$el.getElementsByClassName('neutral')[0];
        let time = 200;
        if(this.collapsed){
            time = 0;
        }
        setTimeout(() => {
          const el = this.$root.$el.getElementsByClassName('complete_false')[0];
          if(el){
              //el.scrollIntoView({behavior: 'smooth', block:    "start"});
              const y = el.getBoundingClientRect().top + window.pageYOffset -100;
              window.scrollTo({top: y, behavior: 'smooth'});
          }
        } , time);
    }
  },
  computed: {
    fields: function(){
      return this.field.form_fields;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/styles.scss";
.form-fieldgroup{
    width: 100%;
    padding:20px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: $bgColorWhite;
    display: flex;
    flex-direction: column;
    .form-fieldgroup-title{
        color:$fontColorHighlighted;
        font-weight: bold;
        padding:5px;
        text-transform: uppercase;
        text-align: left;
    }
    .collapseIconWrap{
      float:right;
      text-align: right;
      .collapseIcon{
        width:20px;
        transition-duration: 0.3s;
        transition-property: transform;
        &.expanded{
          transform: rotate(180deg);
        }
      }
    }
    .sepperator{
      display:none;
    }
}
.form-fieldgroup.collapsible{
  .form-field-wrapper{
    height:0;
    transition: height .2s;
    overflow: hidden;
  }
}
.form-fieldgroup.level1{
  margin: 0 auto 15px auto;
  padding:5px 10px;
  background-color: $bgColorLightLight;
  border-radius: 3px;
  .form-fieldgroup-title{
    color:$fontColorMed;
    font-weight: bold;
    color: $fontColorMedLight;
    text-transform: none;
    text-align: left;
  }
}
.groupCheck{
  border-top:1px solid black;
}
</style>
