<template>
    <div class="textfield-wrap">
        <div class="textfield-col-wrap">
        <textarea v-if="!fix && !oneLine" type="text" :placeholder="placeholder ? placeholder : ''"  v-model='initialValues' @blur="setValue"/>
        <input v-if="!fix && oneLine" type="text" :placeholder="placeholder ? placeholder : ''"  v-model='initialValues' @blur="setValue"/>
        <span class="fix" v-else>{{value}}</span>
        <div class="sepperator"></div>
        <label>{{label}}</label>
        <span v-if='required' class="required"> *</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            values: this.initialValues,
        }
	},
    props:{
        fix:Boolean,
        fieldId:String,
        formId:String,
        taskId:Number,
        parentId:Number,
        required:Boolean,
        value:String,
        label:String,
        placeholder:String,
        oneLine:Boolean,
        isPersonal:Boolean
    },
    computed:{
        initialValues:{
            get () {
                let vals = ''
                let form = this.getCheckResultByFormId(this.formId, this.taskId, this.isPersonal)
                if(form){
                    if(form.fields[this.fieldId]){
                        vals = form.fields[this.fieldId].value;
                    }
                }
                return vals;
            },
            set(val){
                this.values = val;
            }
        },
        ...mapGetters([
            'getCheckResultByFormId',
        ])
    },
    methods:{
        setValue(){
            this.$store.commit("setFieldResult",{'formId':this.formId, 'taskId':this.taskId,'fieldId':this.fieldId,'value':this.values, 'parentId':this.parentId, type:'text', 'isPersonal':this.isPersonal})
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.textfield-wrap{
    width: 100%;
    margin: 10px auto;
    border-radius: 3px;
    background-color: $bgColorLightLight;
    padding:8px 3px;
    display:flex;
    flex-wrap: wrap;
    textarea, input{
        font-family: Avenir, Helvetica, Arial, sans-serif;
        width: 90%;
        font-weight:200;
        background: transparent;
        border: none;
        padding:5px;
        font-size: 1.2rem;
        resize:none;
        outline: none;
        color: $fontColorMedLight;
    }
    .textarea{
        height:100px;
    }

    .textfield-col-wrap{
        flex: 1 1 50%;
    }
    .sepperator{
        border-bottom:1px solid rgba(0,0,0,.1);
        width: 80%;
        margin:auto;
    }
    .fix{
        padding:5px;
    }
    label{
        padding:5px;
        font-size: .7rem;
        font-weight: bold;
        color: $fontColorMedLight;
    }
}
</style>