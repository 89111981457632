<template>
     <div class="image-view" @click.stop='toggleLightbox()'>
        <img class='qrSymbol' :src='getImgUrl("image.svg")'/>
        <div v-if="this.files != undefined" class="lightboxBackground" :class='{"active":this.lightBoxActive}'></div>
        <div v-if="this.files != undefined" class="lightboxControlls" :class='{"active":this.lightBoxActive}' :key='refreshKey'>
            <div v-dragscroll.x="true" v-if="this.files != undefined" class="preview-wrapper">
                <div :id='"index-"+index' class="preview" :class='{"zoomed-preview":index === zoomed}' v-for="(img,index) in prevImages" :key='img.dateModified' @click.stop='zoomImg(index)'>
                    <img class="previewImg" :src='prevImages[index]'/>
                </div>
            </div>
            <div class="zoom-wrap">
                <img class="zoomImg zoomed" :src='prevImages[zoomed]'/>
                <img v-if='prevImgExists' class="imgnav left" @click.stop='prevImg' :src='getImgUrl("Nav_back.svg")'/>
                <img v-if='nextImgExists' class="imgnav right" @click.stop='nextImg' :src='getImgUrl("Nav_forwar.svg")'/>
            </div>
            <backButton class="check" :imgSrc="getImgUrl('Nav_back.svg')"></backButton>
        </div>
    </div>
</template>

<script>

import Button from "@/components/FormFields/Button.vue";

export default {
    data(){
        return{
            file:{},
            zoomed:0,
            lightBoxActive:false,
            refreshKey:1
        }
	},
    components:{
        'backButton':Button,
    },
    props:{
        files: Array,
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        zoomImg(index){
            if(index == this.zoomed || index === undefined){
                this.zoomed = -1;
            }else{
                this.zoomed = index;
            }
        },
        prevImg(){
            if(this.prevImgExists){
                this.zoomed--;
            }
        },
        nextImg(){
            if(this.nextImgExists){
               this.zoomed++;
            }
    
        },
        toggleLightbox(){
            this.lightBoxActive = !this.lightBoxActive;
            if(this.lightBoxActive){
                this.$store.commit('update_docu_image');
                console.log("files: ",this.files);
                if(this.files !== null && this.files !== undefined){
                    this.files.forEach((field)=>{
                        if(field.file_id !== undefined){
                            console.log("field: ",field);
                            this.$store.dispatch("getDocuImages",field);
                        }else{
                            this.$store.commit('update_docu_image',field.fileObject.dataUrl);
                        }
                    });
                }
                window.scrollTo(0,0);
            }
        }
    },
    computed:{
        prevImages:function(){
            return this.$store.state.local.documentation.currentShownImages;
        },
        prevImgExists:function(){
            if(this.zoomed > 0 && this.files[this.zoomed-1] !== undefined){
                return true;
            }
            return false;
        },
        nextImgExists:function(){
            if(this.zoomed >= 0 && this.zoomed < this.prevImages.length -1 && this.prevImages[this.zoomed+1] !== undefined){
                return true;
            }
            return false;
        },
    },
    watch: { 
        zoomed: function(newVal){
            if(document.getElementById('index-'+newVal) !== null){
                document.getElementById('index-'+newVal).scrollIntoView();
            }
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
    .preview-wrapper{
        display: flex;
        justify-content: flex-start;
        overflow-x: hidden;
        .preview{
            display: block;
            flex-basis:20%;
            padding:10px;
            flex-shrink: 0;
        }
        .previewImg{
            display: block;
            max-width: 100%;
            pointer-events:none;
        }
        .zoomed-preview{
            border-bottom:2px solid black;
        }
        .base64view{
            max-width: 300px;
            overflow: hidden;
            float:left;
        }
    }
    .zoom-wrap{
        float:left;
        height: auto;
        position: relative;
        width:100%;
    }
    .zoomImg{
        display:none;
    }
    .zoomed{
        display: block;
        position: relative;
        float: left;
        padding:20px 50px;
        width:auto;
        max-width: 100%;
        z-index: 101;
    }
    .button-wrap{
        float:left;
        margin:20px;
        img{
            height:30px;
            width:30px;
        }
    }
    .lightboxBackground{
        display:none;
        position: fixed;
        width:100vw;
        height:100vh;
        background-color: rgba(255,255,255,1);
        left:0;
        top:0;
        z-index: 100;
        &.active{
            display: block;
        }
    }
    .lightboxControlls{
        position: absolute;
        z-index: 102;
        display: none;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        &.active{
            display: block;
        }
    }
    .imgnav{
        cursor: pointer;
        z-index: 1000;
        position: absolute;
        width: 30px;
        height: 30px;
        margin: auto;
        top: 0;
        bottom: 0;
        cursor: pointer;
        padding:5px;
        &.left{
            left:10px;
        }
        &.right{
            right:10px;
        }
    }
</style>