<!-- shows a list of documentation entries e.g. tours -->
<template>
    <div class="docu-entry-list">
        <div v-for='(entry,index) in entries' :key="index" class="docu-entries-wrap">
            <docuentry :startTime='entry.date_start !== null ? convertAPIDateTimeToString(entry.date_start) : ""' :type='entry.license_plate' :page='page' :tourId='entry.tour_id' :index="index"></docuentry>
        </div>
        <div class="nav-wrap">
            <div class="prev-page-wrap">
                <div 
                    class="prev-page" 
                    v-if='this.page > 1' 
                    v-on:click='prevPage()'>
                    &lt;
                </div>
            </div>
            <div class="page-wrap">
                <div>
                    {{page}} / {{pageCount}}
                </div>
            </div>
            <div class="next-page-wrap">
                <div 
                    class="next-page" 
                    v-if='this.pageCount >= this.page +1'
                    v-on:click='nextPage()'>
                    &gt;
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import DocuEntry from "@/components/DocuEntry.vue";
import dateFunctions from '@/mixins/dateFunctions'
import { mapGetters } from 'vuex';
export default {
    props:{
        page:Number,
        pageCount:Number,
        entries:Array, 
        prevPage:Function,
        nextPage:Function,
    },
    methods: {
       
    },
    components: {
        'docuentry':DocuEntry,
    },
    computed: {
        ...mapGetters([
            'getValueForCurLanguage',
        ])
    },
    mixins: [dateFunctions],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .nav-wrap{
        margin:30px 5%;
        width: 90%;
        display: flex;
        justify-content: space-between;
    }
    .prev-page-wrap{
        flex: 1 0 33.33%;
        .prev-page{
            width: 100%;
            cursor: pointer;
        }
    }
    .next-page-wrap{
        flex: 1 0 33.33%;
        .next-page{
            width:100%;
            cursor: pointer;
        }
    }
</style>