<template>
    <div class="docu-menu">
        <appheader 
            :headline='$t("navigation.documentation")'
        ></appheader>
        <div class="content-wrap">
            <bignavicon
                class='docu-nav'
                :imgSrc='getImgUrl("")'
                link='/docu/tasks'
                :text='$t("documentation.finishedTasksTitle")'>
            </bignavicon>
            <bignavicon
                class='docu-nav'
                :imgSrc='getImgUrl("")'
                link='/docu/tours'
                :text='$t("documentation.finishedToursTitle")'>
            </bignavicon>
            <div class="button-container">
                <routerButton destination='/' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
            </div>
        </div>
    </div>
</template>
<script>
import dateFunctions from '@/mixins/dateFunctions';
import { mapGetters } from 'vuex';
export default {
    data:function () {
        return {
        }
    },
    components: {
    },
    props:{

    },
    methods:{
        getImgUrl(img) {
            let defaultImage = require('@/assets/view-check/form_default.svg')
            try {
                return require('@/assets/'+img);
            } catch (e) {
                return defaultImage
            }    
        },
    },
    computed:{
        ...mapGetters([
            'getValueForCurLanguage',
            'getFormStatus'
        ])
    },
    mixins:[dateFunctions],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.content-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding:5%;
    margin-bottom: 50px;
    .nav-icon-wrap{
        flex: 1 1 100%;
        margin: 2%;
        max-width: 100%;
        max-height: 150px;
        min-height: 100px;
    }
    .button-container{
        width:100%;
    }
}
</style>