<template>
  <div class="personal-data">
    <appheader 
      :headline='$t("personalData.title")'
    ></appheader>
    <hint>{{$t("personalData.hint")}}</hint>
    <formWrap :title='$t("personalData.title")'>
        <textField
            :fix='true'
            formId='personalData'
            :value='firstName'
            :label='$t("personalData.firstname")'
        >
        </textField>
        <textField
            :fix='true'
            formId='personalData'
            :value='lastName'
            :label='$t("personalData.lastname")'
        >
        </textField>
        <textField
            v-for='metafield in metafields'
            :key='metafield.user_meta_id'
            :fix='true'
            formId='personalData' 
            :fieldId='metafield.meta_label !== undefined && Array.isArray(metafield.meta_label) && getValueForCurLanguage(metafield.meta_label).value !== undefined ? getValueForCurLanguage(metafield.meta_label).value : metafield.meta_key'
            :value='metafield.meta_value'
            :label='metafield.meta_label !== undefined && Array.isArray(metafield.meta_label) && getValueForCurLanguage(metafield.meta_label).value !== undefined  ? getValueForCurLanguage(metafield.meta_label).value : metafield.meta_key'>
        </textField>
      
      <!--<textField :fix='true' formId='personalData' fieldId='username' :contents="[{value:fullName, label:'Vor- und Zuname'}]"></textField>
      <textField
        :fix='true'
        formId='personalData' fieldId='street'
        :contents="[
          {value:this.$store.state.user.street, label:'Straße'},
          {value:this.$store.state.user.nr, label:'Nr.'}
        ]">
      </textField>
      <textField
        :fix='true'
        formId='personalData' fieldId='city'
        :contents="[
          {value:this.$store.state.user.city, label:'Ort'},
          {value:this.$store.state.user.plz, label:'PLZ'}
        ]">
      </textField>
      <textField
        :fix='true'
        formId='personalData' fieldId='email'
        :contents="[
          {value:this.$store.state.user.email, label:'E-Mail'},
        ]">
      </textField>-->
    </formWrap>
    <formWrap :title='$t("personalData.settingsTitle")'>
        <div class='login-extra'>
            <div>
                <input class="login-text-field oldpass" :placeholder='this.$t("personalData.oldpassword")' type="password" autocomplete="new-password" v-model="oldpass">
            </div>
            <div>
                <input class="login-text-field newpass" :placeholder='$t("personalData.newpassword")' type="password" autocomplete="new-password" v-model="newpass">
            </div>
            <div>
                <input class="login-text-field newpass2" :placeholder='$t("personalData.reenterpassword")' type="password" autocomplete="new-password" v-model="newpass2">
            </div>
            <div class='change-pw-link' v-on:click="changePw()">{{$t("login.changePassword")}}</div>
        </div>
    </formWrap>
    <formButton destination='./' :imgSrc='getImgUrl("Nav_back.svg")'></formButton>
    <router-view />
    <appfooter></appfooter>
  </div>
</template>

<script>
// @ is an alias to /src
import FormWrapper from "@/components/FormWrapper.vue";
//import TextFieldWithLable from "@/components/FormFields/TextFieldWithLable.vue";
import TextFieldWithLable from "@/components/FormFields/TextAreaWithLable.vue";
import Button from "@/components/FormFields/RouterButton.vue";
import { mapGetters } from 'vuex'

export default {
     data(){
        return {
            oldpass : "",
            newpass : "",
            newpass2: "",
        }
    },
    components: {
        'formWrap' :FormWrapper,
        'textField':TextFieldWithLable,
        'formButton':Button,
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        changePw: function () {
            if(this.oldpass !== "" && this.newpass !== "" && this.newpass2 !== ""){
                if(this.newpass === this.newpass2){
                    this.$store.dispatch('changePassword',{'old_pass': this.oldpass,'new_pass': this.newpass});
                }else{
                    this.$store.dispatch('setStatusMsg',{values:{text: this.$t("messages.passwordChangeSamePW"), type:'error'},time:6000});
                }
            }else{
                this.$store.dispatch('setStatusMsg',{values:{text: this.$t("messages.passwordChangeFieldError"), type:'error'},time:6000});
            }
        }
    },
    computed:{
        metafields: function(){
            //filter out the old name meta fields, remove when all drivers have name entered
            let filteredFields;
            if(this.userName !== undefined){
                filteredFields= this.$store.state.user.meta.groups[0].fields.filter(field => field.meta_key != "firstname" && field.meta_key != "lastname");
            }
            return filteredFields;
        },
        userName:function(){
            return this.$store.state.user.username;
        },
        firstName:function(){
            if(this.$store.state.user.firstname){
                return this.$store.state.user.firstname
            }
            return this.$store.state.user.username;
        },
        lastName:function(){
            if(this.$store.state.user.lastname){
                return this.$store.state.user.lastname
            }
            return "/";
        },
        ...mapGetters([
            'getValueForCurLanguage',
        ])
    },
    beforeCreate() {
        this.$store.dispatch('getProfileData');
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";
.personal-data{
    background-color:$bgColorLight;
    min-height: 100vh;
    .form-wrapper{
        margin-bottom:20px;
    }
    .button-wrap{
        background-color: $bgColorWhite;
        margin: 0 20px 20px 20px;
    }
    .login-text-field, .button{
    border:none;
    background: none;
    width: 80%;
    text-align: center;
    line-height: 2;
    font-size: 1.5em;
    font-weight: 200;
    }
    .login-text-field{
        border-bottom: 1px solid rgba(0,0,0,.1);
        max-width: 400px;
    }
    .button{
        max-width: 300px;
        background-color:$bgColorHighlighted;
        border-radius:25px;
        color: white;
        margin: 60px 0 40px 0;
        cursor:pointer;
    }
}
</style>