<template>
    <div v-if='active' class="overlay">
        <div class="fieldLockWrapper">
            <div class="label">{{field.label}}</div>
            <div class="description">{{field.description}}</div>
            <div v-if='type === "qr"' class="qrLockWrapper">
                <div class="qrTitle">QR-Code</div>
                <QrcodeStream @decode="onDecode"></QrcodeStream>
            </div>
            <span v-if='warning' class="warning">{{$t('messages.wrongQrError')}}</span>
            <div class="buttons-wrap">
                <!--<formButton v-if='this.declineMsg !== undefined' class="decline" :buttonText='declineMsg' v-on:click.native="decline()"></formButton>
                <formButton v-if='this.confirmMsg !== undefined' class="check" :buttonText='confirmMsg' v-on:click.native="confirm()"></formButton>
                <formButton v-else class="check" v-on:click.native="confirm()" :imgSrc="getImgUrl('Nav_check.svg')"></formButton>-->
                <formButton class="decline" v-on:click.native="decline()" :imgSrc="getImgUrl('Nav_decline.svg')"></formButton>
            </div>
        </div>
    </div>
</template>

<script>
//import Button from "@/components/FormFields/Button.vue";
//import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    data(){
        return{
            warning:false,
        }
    },
    components:{
        //'formButton': Button,
        QrcodeStream,
        //QrcodeDropZone,
        //QrcodeCapture
    },
    props:{
        confirmMsg:String,
        declineMsg:String,
        isPersonal:Boolean
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        /*confirm(){
            this.$store.commit("setLockPopup");
        },*/
        decline(){
            this.$store.commit("setLockPopup");
            this.warning = false;
        },
        onDecode (decodedString) {
            if(decodedString !== undefined && decodedString !== ""){
                this.$store.dispatch('setStatusMsg',{values:{text:this.$t('messages.qrScanned'), type:'success'},time:3000});
                if(this.qrValue !== decodedString){
                    this.warning = true;
                }else{
                    this.$store.commit("checkQrValue",{qrValue:decodedString, field:{fieldId:this.field.fieldId, formId:this.field.formId, isPersonal:this.isPersonal}});
                    this.warning = false;
                }
            }else{
                this.$store.dispatch('setStatusMsg',{values:{text:this.$t('messages.qrReadError'), type:'warning'},time:3000});
            }
        }
    },
    computed:{
        active: function(){
            return this.$store.state.status.lockPopup.active;
        },
        type:function(){
            return this.$store.state.status.lockPopup.type;
        },
        field: function(){
            return this.$store.state.status.lockPopup.field;
        },
        qrValue:function(){
            return this.getCheckResultByFormId(this.field.formId,this.field.taskId, this.isPersonal).fields[this.field.fieldId].qrValue;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 1000;
    background-color: $bgColorMedTransparent;
    .fieldLockWrapper{
        padding:20px;
        position: relative;
        width: 85vw;
        margin: auto;
        top:10%;
        background-color: $bgColorLightLight;
        color:$fontColorMed;
        border-radius: 8px;
        .label{
            color: $fontColorHighlighted;
            font-weight: bold;
            text-transform: uppercase;
        }
        .qrTitle{
            margin:10px;
        }
        .buttons-wrap{
            display:flex;
            justify-content: flex-start;
            padding-top:10px;
        }
    }
}
.buttons-wrap .button-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
}
.warning{
    color:$bgColorHighlighted;
    margin: 8px 0;
    display: block;
}
</style>