<template>
    <div 
        class="check-wrap"
        ref="checkRef"
    >
        <div 
            class="check-buttons-wrap"
            :class="[
                value.toString(), 'complete_'+getGroupComplete()
            ]"
        >
        <div class="check-group-button-wrap">
            <div class="check-group-button-wrap-shadow decline-shadow"></div>
            <formButton class="decline" v-on:click.native="setValue(false)" :imgSrc="getImgUrl('Nav_decline.svg')"></formButton>
            
        </div>
        <div class="check-group-button-wrap">
            <div class="check-group-button-wrap-shadow check-shadow"></div>
            <formButton class="check" v-on:click.native="setValue(true)" :imgSrc="getImgUrl('Nav_check.svg')"></formButton>
        </div>    
        </div>
    </div>
</template>

<script>
import Button from "@/components/FormFields/Button.vue";
import { mapGetters } from 'vuex'
export default {
    components:{
        'formButton':Button,
    },
    props:{
        parentId:Number,
        fieldId:Number,
        formId:Number,
        taskId:Number,
        collapseParent: Function,
        scrollToNext: Function,
        parentCollapsed: Boolean,
        highlightField :Function,
        isPersonal: Boolean
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        setValue(value){
            if(this.value !== value){
                if(value === true && this.lockedFields !== false){
                    this.$store.dispatch('setStatusMsg',{values:{text:this.$t('messages.unlockRequiredFields'), type:'warning'},time:5000});
                    this.collapseParent(false, true);
                    this.highlightField(this.lockedFields,400);
                }else{
                    this.$store.commit("setFieldResult",{'formId':this.formId, 'taskId':this.taskId,'fieldId':this.fieldId,'value':value, 'parentId':this.parentId,type:'group', 'isPersonal':this.isPersonal})
                }
            }
        },
        getGroupComplete: function(){
            var groupComplete = this.getGroupState({fieldId:this.fieldId, formId:this.formId},this.isPersonal)
            return groupComplete;
        }
    },
    computed:{
        value: function(){
            var value = this.getFieldResult({fieldId:this.fieldId,parentId:this.parentId,formId:this.formId},this.isPersonal);
            if(value !== undefined){
                return value;
            }else{
                return 'neutral'
            }
        },
        lockedFields: function(){
            let locked = this.getLockedFieldsFromGroup({fieldId:this.fieldId,parentId:this.parentId,formId:this.formId},this.isPersonal);
            if(locked.length <= 0){
                return false;
            }else{
                return locked; 
            }
        },
        ...mapGetters([
            'getFieldResult',
            'getLockedFieldsFromGroup',
            'getGroupState',
        ])
    },
    watch: { 
        value: function(newVal) { // watch it
            if(newVal == true){
                this.scrollToNext();
                this.collapseParent(true, true);
                
            }else{
                this.collapseParent(false, false);
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.check-wrap{
    width: 100%;
    margin: 0 auto;
    border-radius: 3px;
    background-color: $bgColorLightLight;
    padding:8px 0px;
    display:flex;
    flex-direction: column;
    color:$fontColorMedLight;
    .check-buttons-wrap{
        display:flex;
        justify-content: space-between;
        padding:0 10px;
        position: relative;
        .button-wrap img{
            height:30px;
            width:30px;
        }
        .check-group-button-wrap{
            position: relative;
        }
        .check-group-button-wrap-shadow{
            height: 42px;
            width: 90px;
            border-radius: 5px;
            position: absolute;
            top: 5px;
            right: -5px;
            border: 1px solid transparent;
            z-index: 0;
            background-color: $bgColorLight;
            border:1px solid white;
        }
        .decline, .check{
            z-index: 2;
            position: relative;
            border:1px solid white;
        }
        &.true .check,  &.true .check-shadow{
            background-color: $trafficLightColorGreen;
            border: 1px solid black;  
        }
        &.false .decline, &.false .decline-shadow{
            background-color: $trafficLightColorRed;
            border: 1px solid black; 
        }
        &.true .check-shadow, &.false .decline-shadow{
            height: 44px;
        }
    }
    .desc{
        width: 100%;
        padding: 0;
        margin: 0;
        font-size: .7rem;
        font-weight: bold;
        color: $fontColorMedLight;
    }
}
</style>