<template>
    <div class="lock-wrapper">
        <div class="bool-lable-wrap">
            <label>{{label}}</label>
            <p class="desc">{{description}}</p>
        </div>
        <div v-if='lockType === "qr"' class="lockButtonWrapper qr">
            <div class='qrCodeButton' v-on:click='showLockPopup("qr")'>
                <img class='qrIcon' :src='getImgUrl("qr_code.svg")'/> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        locked:Boolean,
        lockType:String,
        formId:Number,
        taskId:Number,
        fieldId:Number,
        parentId:Number,
        label:String,
        description:String,
    },
    methods: {
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        showLockPopup(type){
            this.$store.commit("setLockPopup",{type:type, field:{'formId':this.formId, 'taskId':this.taskId, 'parentId':this.parentId, 'fieldId':this.fieldId, label:this.label, description:this.description}})
        }
    },
    computed:{
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.lock-wrapper{
    width:100%;
}
.lockButtonWrapper{
    padding: 0 10px;
    width:100%;
}
.qrCodeButton{
    width:100%;
    border-radius: 8px;
    background-color: $bgColorLight;
    padding: 6px;
    margin:15px 0;
    height:48px;
    cursor:pointer;
    img{
        display: block;
        width:auto;
        height:36px;
        margin:auto;
    }
}
</style>