<!-- Shows the overview of the documentation page with all the tours -->
<template>
  <div class="docu-task">
    <appheader 
      :headline='$t("documentation.finishedTasksTitleShort")'
    ></appheader>
    <docuentrylist :activeTaskId='taskId' :page='activePage' :pageCount='pageCount' :prevPage='prevPage' :nextPage='nextPage' :entries='entries'></docuentrylist>
    <div class="button-container">
      <routerButton destination='/docu' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
    </div>
    <appfooter></appfooter>
  </div>
</template>

<script>
//import DocuEntry from "@/components/DocuEntry.vue";
import DocuTourEntryList from "@/components/DocuTaskEntryList.vue";

export default {
  data(){
      return{
          detailData: {}
      }
  },
  components:{
    'docuentrylist':DocuTourEntryList
  },
  computed:{
    activePage: function(){
      return this.$store.state.local.documentation.finishedTasksData.activePage;
    },
    entries: function(){
        var entries = this.$store.getters.getDocuTaskEntries(this.activePage);
        return entries;
    },
    pageCount:function(){
        return this.$store.state.local.documentation.finishedTasksData.pageCount;
    },
    taskId: function(){
        if(this.$route.params.taskid !== undefined){
            this.$store.dispatch('getTask', parseInt(this.$route.params.taskid));
            return parseInt(this.$route.params.taskid);
        }else{
            return -1;
        }
    },
  },
  methods:{
    
    getImgUrl(img) {
      return require('@/assets/'+img);
    },
    prevPage:function(){
        if(this.activePage > 1){
            this.$store.dispatch('getFinishedTasksList',this.activePage -1);
            this.$store.commit('set_active_task_doc_page',this.activePage -1)
        }
    },
    nextPage:function(){
        if(this.activePage < this.pageCount){
            this.$store.dispatch('getFinishedTasksList',this.activePage +1);
            this.$store.commit('set_active_task_doc_page',this.activePage +1)
        }
    },
  },
  beforeMount(){
    this.$store.dispatch('getFinishedTasksList',1);
  },
};

</script>

<style scoped lang='scss'>
@import "@/assets/scss/styles.scss";
.docu-task{
    margin-bottom: 50px;
}
.button-container{
  padding:0 20px;
}
</style>
