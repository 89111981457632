<template>
    <div class="bool-wrap" ref="boolRef">
        <div class="bool-lable-wrap">
            <label>{{label}}</label>
            <span v-if='required' class="required"> *</span>
            <p class="desc">{{description}}</p>
        </div>
        <div 
            class="bool-buttons-wrap"
            :class="[
                value.toString()
            ]"
        >
            <formButton class="decline" v-on:click.native="setValue(false)" :imgSrc="getImgUrl('Nav_decline.svg')"></formButton>
            <formButton class="bool" v-on:click.native="setValue(true)" :imgSrc="getImgUrl('Nav_check.svg')"></formButton>
        </div>
    </div>
</template>

<script>
import Button from "@/components/FormFields/Button.vue";
import { mapGetters } from 'vuex';
export default {
    components:{
        'formButton':Button,
    },
    props:{
        fieldId:Number,
        parentId:Number,
        formId:Number,
        taskId:Number,
        label:String,
        required:Boolean,
        propValue:String,
        description:String,
        isPersonal:Boolean
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        setValue(value){
            this.$store.commit("setFieldResult",{'formId':this.formId, 'taskId':this.taskId, 'fieldId':this.fieldId,'value':value, 'parentId':this.parentId,type:'boolean', 'isPersonal':this.isPersonal})
            
            //set options in optionsPopup if value = false and otions exist
            if(this.selectedOption >= 0 && this.selectedOption < this.options.length){
                if(this.options[this.selectedOption].type !== 'option'){
                    this.$store.commit("setOptionsPopup",{'selectedOptionIndex':this.selectedOption, field:{'type':'bool', 'formId':this.formId, 'taskId':this.taskId,'parentId':this.parentId, 'fieldId':this.fieldId, label:this.label, description:this.description}})
                }
            }
        },
    },
    //value for the dynamic css class
    computed:{
        value: function(){
            return this.getFieldResult({fieldId:this.fieldId,taskId:this.taskId,parentId:this.parentId,formId:this.formId}, this.isPersonal);
        },
        parentGroupComplet: function(){
            return this.getGroupState({fieldId:this.parentId,formId:this.formId},this.isPersonal)
        },
        fieldComplete: function(){
            return this.getGroupState({fieldId:this.fieldId,formId:this.formId},this.isPersonal)
        },
        selectedOption: function(){
            if(this.form !== undefined){
                if(this.form.fields[this.fieldId] !== undefined){
                    return this.form.fields[this.fieldId].selectedOption;
                }
            }
            return undefined;
        },
        options: function(){
            if(this.form !== undefined){
                if(this.form.fields[this.fieldId] !== undefined){
                    return this.form.fields[this.fieldId].options;
                }
            }
            return [];
        },
        form: function(){
            return this.getCheckResultByFormId(this.formId, this.taskId, this.isPersonal);
        },
        ...mapGetters([
            'getCheckResultByFormId',
            'getGroupState',
            'getFieldResult'
        ]),
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.level2 .bool-wrap{
    margin: 0 auto;
    background-color: $bgColorLightLight;
}
.bool-wrap{
    width: 100%;
    margin: 8px auto 0 auto;
    border-radius: 10px;
    background-color: $bgColorWhite;
    padding-bottom:8px;
    display:flex;
    flex-direction: column;
    color:$fontColorMedLight;
    .bool-buttons-wrap{
        display:flex;
        justify-content: space-between;
        padding:0 10px;
        .button-wrap img{
            height:30px;
            width:30px;;
        }
        .decline, .bool{
            border:1px solid white;
        }
        &.true .bool{
            background-color: $trafficLightColorGreen;
            border:1px solid black;
        }
        &.false .decline{
            background-color: $trafficLightColorRed;
            border:1px solid black;
        }
    }
    .desc{
        width: 100%;
        padding:0;
        margin:0;
        font-size: .7rem;
        font-weight: bold;
        color: $fontColorMedLight;
    }
}
</style>