<template>
    <div class="signature-wrap">
        <div class="signature-lable-wrap">
            <label>{{label}}</label>
            <span v-if='required' class="required"> *</span>
            <p class="desc">{{description}}</p>
        </div>
        <div class="signature-field-wrap">
            <div class="signature-scrollable-wrap">
                <VueSignaturePad
                    v-if='this.savedSignature === ""'
                    id="signature"
                    width="500px"
                    height="200px"
                    ref="signaturePad"
                    :options="signatureOptions"
                />
                <div class="saved-signature-wrap">
                    <img class="signature-image" v-if='this.savedSignature' :src="this.savedSignature"/>
                </div>
                <div class="signature-underline-wrap" v-if='!this.savedSignature'>
                    <div class="signature-underline"></div>
                </div>
            </div>
            <div class="controlls-wrap">
                <button class="signature-button" @click="clear">{{$t("general.clear")}}</button>
                <button v-if='!this.savedSignature' class="signature-button" @click="save">{{$t("general.save")}}</button>
            </div>
            
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            selectedValue: '',
        }
    },
    props:{
        fieldId:Number,
        parentId:Number,
        formId:Number,
        taskId:Number,
        label:String,
        required:Boolean,
        propValue:String,
        description:String,
        isPersonal:Boolean
    },
    mounted() {
        //console.log("this.savedSignature: ",this.savedSignature)
        //this.$refs.signaturePad.mergeImageAndSignature(this.savedSignature)
    },
    methods: {
        clear() {
            if(this.$refs.signaturePad !== undefined){
                this.$refs.signaturePad.clearSignature();
            }
            this.$store.commit("setFieldResult",{'fileObject':{'dataUrl':''}, 'formId':this.formId, 'taskId':this.taskId, 'fieldId':this.fieldId,'value':"", 'parentId':this.parentId,type:'boolean', 'isPersonal':this.isPersonal})
        },
        save() {
            if(this.$refs.signaturePad !== undefined){
                const signature = this.$refs.signaturePad.saveSignature();
                if(!signature.isEmpty){
                    //console.log(signature.data);
                    this.$store.commit("setFieldResult",{'fileObject':{'dataUrl':signature.data},'formId':this.formId, 'taskId':this.taskId, 'fieldId':this.fieldId,'value':"signed", 'parentId':this.parentId,type:'boolean', 'isPersonal':this.isPersonal})
                }
            }
        },
    },
    //value for the dynamic css class
    computed:{
        signatureOptions: function(){
            let options = {
                penColor: "#3c3c3c",
            }
            return options;
        },
        savedSignature: function(){
            var field = this.getCheckResultByFormId(this.formId, this.taskId, this.isPersonal).fields[this.fieldId]
            let imageToMerge = field !== undefined && field.fileObjects !== undefined && field.fileObjects[field.fileObjects.length -1] !== undefined ? field.fileObjects[field.fileObjects.length -1 ].fileObject.dataUrl : ""
            return imageToMerge;
        },
        languageCode: function(){
            return this.$store.state.user.languageCode;
        },
        parentGroupComplet: function(){
            return this.getGroupState({fieldId:this.parentId,formId:this.formId})
        },
        fieldComplete: function(){
            return this.getGroupState({fieldId:this.fieldId,formId:this.formId})
        },
        selectedOption: function(){
            if(this.form !== undefined){
                if(this.form.fields[this.fieldId] !== undefined){
                    return this.form.fields[this.fieldId].selectedOption;
                }
            }
            return undefined;
        },
        options: function(){
            if(this.form !== undefined){
                if(this.form.fields[this.fieldId] !== undefined){
                    return this.form.fields[this.fieldId].options;
                }
            }
            return [];
        },
        form: function(){
            return this.getCheckResultByFormId(this.formId, this.taskId, this.isPersonal);
        },
        ...mapGetters([
            'getCheckResultByFormId',
            'getGroupState',
            'getFieldResult'
        ]),
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.signature-field-wrap{
    display: flex;
    flex-direction:column;
    .signature-scrollable-wrap{
        width: 100%;
        overflow-x: auto;
        //overflow-y: hidden;
        margin:10px 0 20px 0;
        scrollbar-width: 25px;
        position: relative;
    }
    #signature{
        border:1px solid rgba(0, 0, 0, 0.1);
        margin:10px auto 20px auto;
        //border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
    }
   .signature-button{
        font-size: 1rem;
        border:none;
        border-radius: 5px;
        background-color: $bgColorLight;
        color: $fontColorMedLight;
        padding: 6px;
        margin: 0 5px 0 5px;
        cursor: pointer;
        box-shadow: none;
    }
    .signature-underline-wrap{
        width:500px;
        bottom:60px;
        padding:0 30px;
        position: absolute;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        .signature-underline{
            border-bottom: 1px dotted black;
        }
    }
}
.signature-image{
    width:100%;
    display: block;
    max-height: 200px;
    max-width: 500px;
    margin:auto;
}
.controlls-wrap{
    margin-top: auto;
    margin-bottom: 5px;
}
</style>