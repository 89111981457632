<template>
  <div class="persTasks-form">
    <appheader 
        :headline='formId > -1 && taskForm !== undefined ? getValueForCurLanguage(taskForm.form_title).value : "lade task"'>
    </appheader>
    <MyForm 
      :form='taskForm'
      :formBaseId='formId > -1 && taskForm !== undefined ? taskForm.form_base_id : -1'
      :readyToSend='formReadyToSend'
      nextRoute='/'
      backRoute='/'
      :isPersonal='true'>
    </MyForm>
    <appfooter></appfooter>
  </div>
</template>

<script>
import MyForm from '@/components/Form.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    'MyForm': MyForm,
  },
  computed:{
    formReadyToSend: function(){
      var status = this.getFormFieldStatus({formId:this.formId, taskId:this.taskId}, true);
      if(status == "ok" || status == "failed" || status == "warning"){
        return true;
      }else{
        return false;
      }
    },
    formId: function(){
        if(this.$route.params.formid !== undefined){
            return parseInt(this.$route.params.formid);
        }else{
            return -1;
        }
    },
    taskId: function(){
        if(this.$route.params.taskid !== undefined){
            this.$store.dispatch('getTask', parseInt(this.$route.params.taskid));
            return parseInt(this.$route.params.taskid);
        }else{
            return -1;
        }
    },
    taskForm: function(){
        return this.getPersTaskByTaskId(this.taskId);
    },
    ...mapGetters([
        'getValueForCurLanguage',
        'getPersTaskByTaskId',
        'getFormFieldStatus'
    ])
  },
};
</script>
