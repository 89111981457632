
<!-- shows a list of documentation entries e.g. tours -->
<template>
    <div class="docu-entry-list">
        <div class="task-group-title">{{$t("documentation.personalTasksTitle")}}</div>
        <div v-for='(entry,index) in personalTasks' :key="entry.form_task_id" class="docu-entries-wrap">
            <docutaskentry :isActiveDetail='activeTaskId === entry.form_task_id ? true : false' :completionTime='convertAPIDateTimeToString(entry.date_completed)' :title='getValueForCurLanguage(entry.task_name).value' :page='page' :taskId='entry.form_task_id' :tourId='entry.tour_id' :index="index"></docutaskentry>
        </div>
        <div class="task-group-title">{{$t("documentation.vehicleTasksTitle")}}</div>
        <div v-for='(entry,index) in vehicleTasks' :key="index" class="docu-entries-wrap">
            <docutaskentry :completionTime='convertAPIDateTimeToString(entry.date_completed)' :title='getValueForCurLanguage(entry.task_name).value' :page='page' :taskId='entry.form_task_id' :tourId='entry.tour_id' :index="index"></docutaskentry>
        </div>
        <div class="nav-wrap">
            <div class="prev-page-wrap">
                <div 
                    class="prev-page" 
                    v-if='this.page > 1' 
                    v-on:click='prevPage()'>
                    &lt;
                </div>
            </div>
            <div class="page-wrap">
                <div>
                    {{page}} / {{pageCount}}
                </div>
            </div>
            <div class="next-page-wrap">
                <div 
                    class="next-page" 
                    v-if='this.pageCount >= this.page +1'
                    v-on:click='nextPage()'>
                    &gt;
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import DocuTaskEntry from "@/components/DocuTaskEntry.vue";
import dateFunctions from '@/mixins/dateFunctions'
import { mapGetters } from 'vuex';
export default {
    props:{
        page:Number,
        pageCount:Number,
        entries:Array, 
        prevPage:Function,
        nextPage:Function,
        activeTaskId:Number
    },
    methods: {
       
    },
    components: {
        'docutaskentry':DocuTaskEntry
    },
    computed: {
        personalTasks:function(){
            //needed because this.entries was undefined sometimes, that broke the reactiveness
            if(this.entries !== undefined){
                return this.entries.filter(entry=>{
                    return entry.tour_id === undefined || entry.vehicle_id === undefined || entry.vehicle_id === null;
                })
            }
            return undefined;
        },
        vehicleTasks:function(){
            //needed because this.entries was undefined sometimes, that broke the reactiveness
            if(this.entries !== undefined){
                return this.entries.filter(entry=>{
                    return entry.tour_id !== undefined && (entry.vehicle_id !== null || entry.vehicle_id === undefined);
                })
            }
            return undefined;
        },
        ...mapGetters([
            'getValueForCurLanguage',
        ])
    },
    mixins: [dateFunctions],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "@/assets/scss/styles.scss";
    .nav-wrap{
        margin:30px 5%;
        width: 90%;
        display: flex;
        justify-content: space-between;
    }
    .prev-page-wrap{
        flex: 1 0 33.33%;
        .prev-page{
            width: 100%;
            cursor: pointer;
        }
    }
    .next-page-wrap{
        flex: 1 0 33.33%;
        .next-page{
            width:100%;
            cursor: pointer;
        }
    }
    .task-group-title{
        margin:10px;
        font-size: 1.2rem;
        color:$fontColorHighlighted;
    }
</style>