<template>
    <div class="select-wrap" ref="selectRef">
        <div class="select-lable-wrap">
            <label>{{label}}</label>
            <span v-if='required' class="required"> *</span>
            <p class="desc">{{description}}</p>
        </div>
        <div 
            class="select-field-wrap"
        >
            <select :name="label" :id="label" v-model="selectedValue" v-on:change="setValue($event.target.selectedIndex)">
                <option v-for='(option, index) in options' :key='index' :value="option.value">{{option.label[languageCode].value}}</option>
            </select> 
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            selectedValue: ''
        }
    },
    props:{
        fieldId:Number,
        parentId:Number,
        formId:Number,
        taskId:Number,
        label:String,
        required:Boolean,
        propValue:String,
        description:String,
        isPersonal:Boolean
    },
    mounted(){
        this.selectedValue = this.value
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        //bring set value to parent component - decoupling
        setValue(index){
            let value = this.selectedValue;
            this.$store.commit("setFieldResult",{'formId':this.formId, 'taskId':this.taskId,'fieldId':this.fieldId,'value':value, 'parentId':this.parentId, type:'radio', index:index, 'isPersonal':this.isPersonal});
            if(this.selectedOption >= 0 && this.selectedOption < this.options.length){
                if(this.options[this.selectedOption].type !== 'option'){
                    this.$store.commit("setOptionsPopup",{'selectedOptionIndex':this.selectedOption, field:{'formId':this.formId,'taskId':this.taskId, 'parentId':this.parentId, 'fieldId':this.fieldId, label:this.label, description:this.description}})
                }
            }
        },
    },
    //value for the dynamic css class
    computed:{
        value: function(){
            var value = this.getFieldResult({fieldId:this.fieldId,taskId:this.taskId,parentId:this.parentId,formId:this.formId}, this.isPersonal);
            return value;
        },
        languageCode: function(){
            return this.$store.state.user.languageCode;
        },
        parentGroupComplet: function(){
            return this.getGroupState({fieldId:this.parentId,formId:this.formId})
        },
        fieldComplete: function(){
            return this.getGroupState({fieldId:this.fieldId,formId:this.formId})
        },
        selectedOption: function(){
            if(this.form !== undefined){
                if(this.form.fields[this.fieldId] !== undefined){
                    return this.form.fields[this.fieldId].selectedOption;
                }
            }
            return undefined;
        },
        options: function(){
            if(this.form !== undefined){
                if(this.form.fields[this.fieldId] !== undefined){
                    return this.form.fields[this.fieldId].options;
                }
            }
            return [];
        },
        form: function(){
            return this.getCheckResultByFormId(this.formId, this.taskId, this.isPersonal);
        },
        ...mapGetters([
            'getCheckResultByFormId',
            'getGroupState',
            'getFieldResult'
        ]),
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.level2 .select-wrap{
    margin: 0 auto;
    background-color: $bgColorLightLight;
}
.select-wrap{
    width: 100%;
    margin: 8px auto 0 auto;
    border-radius: 10px;
    background-color: $bgColorWhite;
    padding:8px 0px;
    display:flex;
    flex-direction: column;
    color:$fontColorMedLight;
    .select-field-wrap{
        display:flex;
        justify-content: space-between;
        padding:0 10px;
        .button-wrap img{
            height:30px;
            width:30px;;
        }
        .decline, .select{
            border:1px solid white;
        }
        &.true .select{
            background-color: $trafficLightColorGreen;
            border:1px solid black;
        }
        &.false .decline{
            background-color: $trafficLightColorRed;
            border:1px solid black;
        }
        select{
            border: none;
            width: 100%;
            height: 35px;
            margin: 10px 0;
            background-color: $bgColorLight;
            padding: 5px;
        }
    }
    .desc{
        width: 100%;
        padding:0;
        margin:0;
        font-size: .7rem;
        font-weight: bold;
        color: $fontColorMedLight;
    }
}
</style>