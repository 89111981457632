<template>
    <div class="Check">
        <appheader 
        headline="Tour"
        v-bind:rowOne='{
            userName:fullName,
            company: this.$store.state.user.company
        }'
        v-bind:rowTwo='{
            colOne:{
            head:$t("header.tourStarted1"),
            content: noVehicleSet === false ? this.$store.getters.driveCheckStartDate : "/",
            foot:$t("header.tourStarted2"),
            },
            colTwo:{
            head:$t("header.licensePlate"),
            content:this.$store.getters.currVehiclePlate,
            foot:$t("header.vehicle")
            },
            colThree:{
            head:$t("header.licensePlate"),
            content:this.$store.getters.currTrailerPlate,
            foot:$t("header.trailer")
            },
            expandable:false
        }'
        v-bind:rowThree='{
            active:true,
            colOne:{
            status:this.$store.getters.getTotalStatus,
            foot:$t("header.totalStatus")
            },
        }'
        ></appheader>
        <timerPopup
            :visible="timerPopupActive"
            :setTimerPopupState='setTimerPopupState'
            popupTextValue=''
            popupKmValue=''
            popupLabelValue='Zeiterfassung stoppen'
            curEntryType='stop'>
        </timerPopup>
        
        <div class="content-wrap">
            <bignavicon-group
                v-if='getFormsByType("checklist").length > 0'
                :lists='getFormsByType("checklist")'
                :class="{'disabled':noVehicleSet}"
                :imgSrc='getImgUrl("view-check/form_checklist.svg")'>
            </bignavicon-group>
            <bignavicon-group
                v-if='getFormsByType("todolist").length > 0 && isSupported("ToDo")'
                :lists='getFormsByType("todolist")'
                :class="{'disabled':noVehicleSet}"
                :imgSrc='getImgUrl("view-check/form_todolist.svg")'>
            </bignavicon-group>
            <bignavicon-group
                v-if='getTourTasks !== undefined && getTourTasks.length > 0'
                :lists='getTourTasks'
                :class="{'disabled':noVehicleSet}"
                :imgSrc='getImgUrl("view-check/form_todolist.svg")'
                :title='$t("navigation.tasks")'
                :groupIsDefault='true'>
            </bignavicon-group>
            <bignavicon-group
                v-if='getFormsByNotType(["faultlist","customer","accident","checklist","todolist","endcontroll"]).length > 0'
                :lists='getFormsByNotType(["faultlist","customer","accident","checklist","todolist","endcontroll"])'
                :class="{'disabled':noVehicleSet}"
                :imgSrc='getImgUrl("view-check/form_default.svg")'
                :title='$t("navigation.recurring")'
                :groupIsDefault='true'>
            </bignavicon-group>
            <bignavicon
                v-if='isSupported("time")'
                :class="{'disabled':noVehicleSet}"
                :imgSrc='getImgUrl("view-check/Nachtrag.svg")' 
                link="/check/time" 
                :text='$t("navigation.report")'
                :status='this.$store.getters.getFormStatus({formId:"time"})'>
            </bignavicon>
            <bignavicon-group
                v-if='getFormsByType(["faultlist","customer","accident"]).length > 0'
                :lists='getFormsByType(["faultlist","customer","accident"])'
                :class="{'disabled':noVehicleSet}"
                :imgSrc='getImgUrl("view-check/form_faultlist.svg")'
                :title='$t("navigation.event")'
                :optionalForms='true'
                :groupIsDefault='true'>
            </bignavicon-group>
            <bignaviconNoRouter 
                v-if='getFormsByType("endcontroll").length > 0 &&(this.$store.state.local.nachtragTimer.currentlyTracked !== "stop" && this.$store.state.local.nachtragTimer.currentlyTracked !== "none")'
                :class="{'disabled':noVehicleSet}"
                :imgSrc='getImgUrl("view-check/form_endcontroll.svg")'
                :text='$t("navigation.end")'
                :status='this.$store.getters.getFormStatus({formId:this.$store.state.local.curCheck.endId.formId})'
                v-on:click.native='clickEndcontroll'>
            </bignaviconNoRouter>
            <bignavicon-group
                v-else-if='getFormsByType("endcontroll").length > 0'
                :lists='getFormsByType("endcontroll")'
                :class="{'disabled':noVehicleSet}"
                :imgSrc='getImgUrl("view-check/form_endcontroll.svg")'
                :title='$t("navigation.end")'
                :overrideTitle='true'>
            </bignavicon-group>
            <div class="button-container">
                <routerButton destination='/' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
                <formButton class="confirmButton" v-if='this.getTotalStatus !== 2' :imgSrc='getImgUrl("Nav_check.svg")' v-on:click.native='clickFinishCurCheck'></formButton>
            </div>
        </div>
        <confirmpopup :active='this.confirmActive' msg='Die aktuelle Fahrt abschließen?' :confirmed='this.confirm' :declined='this.decline'></confirmpopup>
        <appfooter></appfooter>
    </div>
</template>

<script>
// @ is an alias to /src
import BigNavIconGroup from "@/components/BigNavIconGroup.vue";
import BigNavIconNoRouter from "@/components/BigNavIconNoRouter.vue";
import TimerPopup from "@/components/Popups/TimerPopup.vue";
import { mapGetters } from 'vuex';
export default {
  components: {
    'bignaviconNoRouter' : BigNavIconNoRouter,
    'bignavicon-group' : BigNavIconGroup,
    'timerPopup' : TimerPopup,
  },
  data(){
      return{
          confirmActive: false,
          timerPopupActive: false,
      }
  },
  methods:{
    isSupported(feature){
        return this.isFeatureSupportet(feature);
    },
    getImgUrl(img) {
        let defaultImage = require('@/assets/view-check/form_default.svg')
        try {
            return require('@/assets/'+img);
        } catch (e) {
            return defaultImage
        }    
    },
    clickFinishCurCheck(){
        this.confirmActive=true;
    },
    decline:function(){
        this.confirmActive = false; 
    },
    confirm:function(){
        this.$store.dispatch('endTour');
        this.$router.push('/');
        //this.$store.commit('finishCurCheck');
        this.confirmActive = false; 
    },
    clickEndcontroll:function(){
       this.timerPopupActive = true;
    },
    setTimerPopupState(state){
        this.timerPopupActive = state;
    },
  },
  computed:{
    noVehicleSet:function(){
      if(this.$store.state.local.curCheck.chosenTruck >= 0){
        return false;
      }
      return true;
    },
    fullName:function(){
      if(this.$store.state.user.firstname || this.$store.state.user.lastname){
        return this.$store.state.user.firstname + " " + this.$store.state.user.lastname
      }
      return this.$store.state.user.username;
    },
    ...mapGetters([
        'getValueForCurLanguage',
        'getFormsByType',
        'getTotalStatus',
        'isFeatureSupportet',
        'getTourTasks',
        'getFormsByNotType'

    ])
  },
  beforeMount:function(){
    //set start date of check when check is opend and the date is not set allready
    this.$store.commit('setCurCheckStartTime');
  },
};
</script>

<style lang='scss'>
@import "@/assets/scss/styles.scss";
.Check{
    .content-wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding:5%;
        margin-bottom: 50px;
        .nav-icon-wrap, .nav-icon-group{
            margin:2%;
            max-width: 150px;
            min-width: 150px;
            max-height: 150px;
            min-height: 150px;
            @media all and (max-width:360px){
                max-width:100%;
                max-height:auto;
            }
        }
        .nav-icon-group .nav-icon-wrap{
            background-color: transparent;
        }
        .nav-group-dropdown .dropdown-content-wrap{
            .nav-icon-wrap{
                width:100%;
                min-width: 100%;
                background-color: $bgColorLight;
            }
        }
    }
    .button-container{
        width:100%;
        display:flex;
        justify-content: space-between;
        }
    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}
</style>