<!-- Shows the overview of the documentation page with all the tours -->
<template>
  <div class="docu-tour">
    <appheader 
      :headline='$t("documentation.finishedToursTitleShort")'
    ></appheader>
     <docuentrylist :page='activePage' :prevPage='prevPage' :pageCount='pageCount' :nextPage='nextPage' :entries='entries'></docuentrylist>
    <div class="button-container">
      <routerButton destination='/docu' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
    </div>
    <appfooter></appfooter>
  </div>
</template>

<script>
//import DocuEntry from "@/components/DocuEntry.vue";
import DocuEntryList from "@/components/DocuEntryList.vue";


export default {
  data(){
      return{
          detailData: {}
      }
  },
  components:{
    'docuentrylist':DocuEntryList
  },
  computed:{
    activePage: function(){
      return this.$store.state.local.documentation.finishedToursData.activePage;
    },
    entries: function(){
        var entries = this.$store.getters.getDocuEntries(this.activePage);
        return entries;
    },
    pageCount:function(){
        return this.$store.state.local.documentation.finishedToursData.pageCount;
    }
  },
  methods:{
    getImgUrl(img) {
      return require('@/assets/'+img);
    },
    prevPage:function(){
        if(this.activePage > 1){
            this.$store.dispatch('getTourList',this.activePage -1);
            this.$store.commit('set_active_doc_page',this.activePage -1)
        }
    },
    nextPage:function(){
        if(this.activePage < this.pageCount){
            this.$store.dispatch('getTourList',this.activePage +1);
            this.$store.commit('set_active_doc_page',this.activePage +1)
        }
    },
  },
  beforeMount(){
    this.$store.dispatch('getTourList',1);
  },

};

</script>

<style scoped lang='scss'>
@import "@/assets/scss/styles.scss";
.docu-tour{
    margin-bottom: 50px;
}
.button-container{
  padding:0 20px;
}
</style>
