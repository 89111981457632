<template>
  <div class="default-form">
    <appheader
        :headline='formId > -1 && this.form !== undefined 
        ?getValueForCurLanguage(this.form.form_title).value 
        :$t("general.nothingFoundTitle")'>
    </appheader>
    <MyForm 
        :form='form'
        :formBaseId='formId > -1 && this.form !== undefined ? this.form.form_base_id : -1'
        :readyToSend='formReadyToSend'
        nextRoute='/check'>
    </MyForm>
    <div v-if='this.form !== undefined && this.form.form_type === "faultlist" && sentDefectsList.length > 0' class="sentDefectsList">
        <div class="sentDefectsTitle">{{$t("tour.defects.existingDefects")}}</div>
        <div v-for='(defect,index) in sentDefectsList' class="sentDefect" :key="index">
            <div v-for='(field,ind) in defect' class="sentDefectField" :key="ind">
                <div class="defectLabel">{{getValueForCurLanguage(field.label).value}}:</div><div class="defectValue">{{field.value}}</div>
            </div>
        </div>
    </div>
    <appfooter></appfooter>
  </div>
</template>

<script>
import MyForm from '@/components/Form.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    'MyForm': MyForm,
  },
  computed:{
    formReadyToSend: function(){
        var status = this.getFormFieldStatus({formId:this.formId});
        if(status == "ok" || status == "failed" || status == "warning"){
            return true;
        }else{
            return false;
        }
    },
    formId: function(){
        if(this.$route.params.id !== undefined){
            return parseInt(this.$route.params.id);
        }else{
            return -1;
        }
    },
    form: function(){
        return this.getFormByFormId(this.formId);
    },
    sentDefectsList: function(){
        return this.$store.state.local.sentDefects;
    },
    ...mapGetters([
        'getValueForCurLanguage',
        'getFormByFormId',
        'getFormFieldStatus'
    ])
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/styles.scss";
.default-form{
    background-color:$bgColorLight;;
}
.sentDefectsList, .sentDefectsList{
    width: 90%;
    padding: 20px;
    margin: 10px auto;
    border-radius: 10px;
    background-color: white;
    text-align: left;
    .sentDefectsTitle{
        margin:10px 0;
        font-size: 1.2rem;
        color:$fontColorHighlighted;
    }
    .sentDefect{
        background-color:$bgColorLightLight;
        border-radius: 3px;
        margin:10px 0;
        padding:10px;
    }
    .sentDefectField{
        margin:3px 0;
        display: flex;
        justify-content: space-between;
    }
    .defectValue{
        text-align: right;
    }
}
</style>
