<template>
    <div class="radio-wrap">
        <div class="radio-lable-wrap">
            <label>{{label}}</label>
            <span v-if='required' class="required"> *</span>
            <p class="desc">{{description}}</p>
        </div>
        <div 
            class="radio-options-wrap"
            :class="[
                value.toString()
            ]"
        >   
        <!-- TODO! what to use as unique name for the radio group? -->
            <label v-for='(option, index) in options' :key='index' class="radio-option">
                <input :id='index' type="radio" :checked="value===index" :name='"radio"+fieldId' v-on:change="setValue(index)"/>
                <span class="checkmark"></span>
                {{option.label[languageCode].value}}
            </label>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props:{
        fieldId:Number,
        parentId:Number,
        formId:Number,
        taskId:Number,
        label:String,
        propValue:String,
        description:String,
        required:Boolean,
        isPersonal:Boolean
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        //bring set value to parent component - decoupling
        setValue(value){
            this.$store.commit("setFieldResult",{'formId':this.formId, 'taskId':this.taskId,'fieldId':this.fieldId,'value':value, 'parentId':this.parentId, type:'radio', index:value, 'isPersonal':this.isPersonal});
            if(this.selectedOption >= 0 && this.selectedOption < this.options.length){
                if(this.options[this.selectedOption].type !== 'option'){
                    this.$store.commit("setOptionsPopup",{'selectedOptionIndex':this.selectedOption, field:{'formId':this.formId,'taskId':this.taskId, 'parentId':this.parentId, 'fieldId':this.fieldId, label:this.label, description:this.description}})
                }
            }
        },
    },
    computed:{
        value: function(){
            var value = this.getFieldResult({fieldId:this.fieldId,parentId:this.prentId,formId:this.formId}, this.isPersonal);
            return value;
        },
        languageCode: function(){
            return this.$store.state.user.languageCode;
        },
        selectedOption: function(){
            if(this.form !== undefined){
                if(this.form.fields[this.fieldId] !== undefined){
                    return this.form.fields[this.fieldId].value;
                }
            }
            return undefined;
        },
        options: function(){
            if(this.form !== undefined){
                if(this.form.fields[this.fieldId] !== undefined){
                    return this.form.fields[this.fieldId].options;
                }
            }
            return [];
        },
        form: function(){
            return this.getCheckResultByFormId(this.formId, this.taskId, this.isPersonal);
        },
        ...mapGetters([
            'getCheckResultByFormId',
            'getFieldResult'
        ])
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.level2 .radio-wrap{
    margin: 0 auto;
    background-color: $bgColorLightLight;
}
.radio-wrap{
    width: 100%;
    margin: 8px auto 0 auto;
    border-radius: 10px;
    background-color: $bgColorWhite;
    padding:8px 8px;
    display:flex;
    flex-direction: column;
    color:$fontColorMedLight;
    .radio-options-wrap{
        display:flex;
        flex-direction: column;
        text-align: left;
        .radio-option{
            line-height: 3;
        }
    }
    .desc{
        width: 100%;
        padding:0;
        margin:0;
        font-size: .7rem;
        font-weight: bold;
        color: $fontColorMedLight;
    }
}
</style>