<template>
  <div class="form-fieldgroup" :class='{collapsible: this.collapsible}'>
    <div class="form-fieldgroup-title">
        <span v-if='field.field_label'>{{field.field_label[this.$store.state.user.languageCode].value}}</span>
        <span v-else>{{field.field_name[this.$store.state.user.languageCode].value}}</span>
    </div>
    <div class="form-field-wrapper">
      <div class="form-field" v-for='child in fields' :key="child.form_field_id">
        <FormFieldGroupView
          :class="'level'+child.level"
          v-if="child.field_type.type === 'group'"
          :field="child"
          :formId='formId'
          :collapsible="canCompleteChildren(child.can_complete_children)"
          :submissionId="submissionId"
        />
        <FormFieldView
          v-else-if='child.skip !== true'
          :class="'level'+child.level"
          :field="child"
          :formId='formId'
          :highlighted='highlightedFields.includes(child.form_field_id)'
          :submissionId="submissionId"
        />
      </div>
    </div>
    
  </div>
</template>

<script>
import FormFieldView from "@/components/FormFieldsView/FormFieldView.vue";
//for browser that do not support windo.scrollTo -> behaviour:'smooth'
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

export default {
  name: 'FormFieldGroupView',
  props: {
    submissionId: String,
    field: {
      type: Object,
      default() {
        return {};
      },
    },
    formId: Number,
    language: String,
    collapsible:Boolean,
  },
  data() {
    return {
      name: null,
      hovering: false,
      collapsed: false,
      showControlls: true,
      highlightedFields:[],
    };
  },
  components: {
    'FormFieldView':FormFieldView,
  },
  created() {
    if (!this.field) return;
    if (this.field.field_name) this.name = this.field.field_name;
  },
  methods: {
      //diese methode nur nötig da manchmal string statt bool ...
    canCompleteChildren(collapsible){
        if(typeof collapsible === 'string'){
            return (collapsible === 'true');
        }
        return collapsible;
    },
    collapse(value, showControlls){
      if(value !== undefined){
        this.collapsed = value;
      }else{
        this.collapsed = !this.collapsed;
      }
      if(showControlls !== undefined){
        this.showControlls = showControlls;
      }
    },
    setHighlightFields(ids, time){
        let defaultTime = 3000;
        if(time){
            defaultTime = time;
        }
        this.highlightedFields = ids;
        setTimeout(() => {
            this.highlightedFields = [];
        },defaultTime);
        
    },
    scrollToNextField(){
        //const el = this.$el.getElementsByClassName('neutral')[0];
        let time = 200;
        if(this.collapsed){
            time = 0;
        }
        setTimeout(() => {
          const el = this.$root.$el.getElementsByClassName('complete_false')[0];
          if(el){
              //el.scrollIntoView({behavior: 'smooth', block:    "start"});
              const y = el.getBoundingClientRect().top + window.pageYOffset -100;
              window.scrollTo({top: y, behavior: 'smooth'});
          }
        } , time);
    }
  },
  computed: {
    fields: function(){
      return this.field.form_fields;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/styles.scss";
.form-fieldgroup{
    width: 100%;
    padding:20px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: $bgColorWhite;
    display: flex;
    flex-direction: column;
    .form-fieldgroup-title{
        color:$fontColorHighlighted;
        font-weight: bold;
        padding: 0;
        text-transform: uppercase;
        text-align: left;
    }
    .collapseIconWrap{
      float:right;
      text-align: right;
      .collapseIcon{
        width:20px;
        transition-duration: 0.3s;
        transition-property: transform;
        &.expanded{
          transform: rotate(180deg);
        }
      }
    }
    .sepperator{
      display:none;
    }
}
.form-fieldgroup{
    .form-field-wrapper{
        height:auto;
        overflow: hidden;
    }
}
.form-fieldgroup.level1{
  padding:10px 15px;
  background-color: $bgColorLightLight;
  border-radius: 3px;
  .form-fieldgroup-title{
    color:$fontColorMed;
    font-weight: bold;
    color: $fontColorMedLight;
    text-transform: none;
    text-align: left;
    margin-bottom: 8px;
  }
}
.groupCheck{
  border-top:1px solid black;
}
</style>
