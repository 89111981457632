<template>
    <div class="timer-wrap">
        <div class="label-wrap">
            <div v-if='active' class="activeMarker"></div>
            <label>{{label}}</label>
        </div>
        <div class="time-wrap">
            <span>{{this.getDisplayTime}}</span>
        </div>
        
    </div>
</template>

<script>
export default {
    props:{
        label:String,
        secondsValue:Number,
        active:Boolean,
    },
    data(){
        return{
            now: Date.now()/1000,
            timer:null,
        }
    },
    watch: { 
        active: function(newVal) { // watch it
            if(newVal == true){
                if(this.active){
                    this.timer = setInterval(() => {
                        this.now = Date.now()/1000
                    },1000)
                }
            }else{
                clearInterval(this.timer);
                //this.startTime = -1;
            }
        }
    },
    computed:{
        _seconds: () => 1,
        _minutes() {
            return this._seconds * 60;
        },
        _hours() {
            return this._minutes * 60;
        },
        getDisplayTime(){
            var secondsGes = this.secondsValue;
            if(this.active && this.now - this.startTime > 0){
                secondsGes= secondsGes + (this.now -this.startTime)
            }
            const hours = Math.floor(secondsGes  / this._hours);
            const minutes = Math.floor((secondsGes % this._hours) / this._minutes);
            const seconds = Math.floor((secondsGes % this._minutes) / this._seconds);
            let displayHours = hours < 10 ? "0" + hours : hours;
            let displayMinutes = minutes < 10 ? "0" + minutes : minutes;
            let displaySeconds = seconds < 10 ? "0" + seconds : seconds;
            return displayHours + ":" + displayMinutes + ":" + displaySeconds;
        },
        startTime:{
            get(){
                return this.$store.state.local.nachtragTimer.startTimeActualTimer
            },
        }
    },
    mounted(){
        if(this.active)(
            this.timer = setInterval(() => {
                this.now = Date.now()/1000
            },1000)
        )
    },
    beforeDestroy(){
      clearInterval(this.timer);
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/styles.scss";
    .timer-wrap{
        display:flex;
        justify-content: space-between;
        padding: 3px 20px;
        font-size: 1.4rem;
        .label-wrap{
            position: relative;
        }
    }
    .activeMarker{
        width: 8px;
        height: 8px;
        border-radius: 6px;
        background-color: $trafficLightColorRed;
        margin:8px;
        display:inline-block;
        vertical-align: top;
        position: absolute;
        left:-20px;
        top:2px;
    }
</style>