<template>
  <div class="loading-page">
    <appheader
        :headline='text'>
    </appheader>
    <div class="loading-content">
        <div class="loading-text">{{text}}</div>
        <routerButton class="backButton" destination='/check/' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
    </div>
    <appfooter></appfooter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    mounted(){
        //TODO: handle finished tours/submissions differently (should show the documentation details and not the form again)
        
        let taskId = parseInt(this.$route.params.taskid);
        if(taskId !== undefined){
            /*let checklistExistsIndex = this.$store.state.local.curCheck.tourTasks.findIndex(obj => {
                return obj.task.form_task_id === taskId;
            });
            console.log("checklistExistsIndex: ",checklistExistsIndex)*/
            //task does not exist in the app
            this.$store.dispatch('getTask', taskId).then((res) => {
                //task was already completed -> get the submission
                console.log("res.data.completed_user_id: ",res.data.completed_user_id)
                if(res.data.completed_user_id !== undefined && res.data.completed_user_id !== null){
                    //save the submissions for the task
                    /*if(res.data.form_submission_id !== undefined && res.data.form_submission_id !== null){
                        this.$router.push('/docu/submission/'+res.data.form_submission_id).catch((e) => {console.log(e); return;});
                    }else{*/
                        
                        let resObject = this.getCheckResultByFormId(this.formId, this.taskId, this.isPersonal)
                        //if no result for the task exists, generate a form with task and init the results
                        if(resObject === undefined){
                            let{form, ...newTaskWithoutForm} = res.data
                            newTaskWithoutForm
                            let formType = {'form_type':"task"};
                            let newFormWithTask = {...form, task:newTaskWithoutForm, ...formType}
                            this.$store.commit('initCheckResults', newFormWithTask)
                        }
                        this.$store.commit("setTaskDocuDetails",res.data);
                        this.$router.push('/docu/task/'+res.data.form.form_id + '/' + taskId + '/' + ((res.data.vehicle_id !== null) ? "0" : "1")).catch((e) => {console.log(e); return;});
                    //}
                }else{
                    //rout to the task view
                    if(res.data.vehicle_id !== null){
                        this.$router.push('/check/task/'+res.data.form.form_id + '/' + taskId).catch(() => {});
                    }else{
                        this.$router.push('/check/tasks/'+res.data.form.form_id + '/' + taskId).catch(() => {});
                    }
                }
            }); 
        }else{
            this.$router.push('/docu');
        }
    },
    computed:{
        text: function(){
            if(this.$route.params.taskid !== "undefined" && this.$route.params.taskid !== undefined){
                return this.$t("navigation.taskloading");
            }else{
                return this.$t("navigation.nothingToLoad");
            }
        },
         ...mapGetters([
            'getCheckResultByFormId'
        ])
    },
    methods:{
        getImgUrl(img) {
            let defaultImage = require('@/assets/view-check/form_default.svg')
            try {
                return require('@/assets/'+img);
            } catch (e) {
                return defaultImage
            }    
        },
    }
};
</script>
<style lang="scss">
@import "@/assets/scss/styles.scss";
.loading-content{
    padding:30px;
}
.backButton .button-wrap, .confirmButton{
    background-color: $bgColorLight;
}
</style>
