<!-- shows the details of a specific form of a tour e.g. endcontroll -->
<template>
  <div class="docuDetail">
    <appheader 
        v-if='detailData !== undefined'
      :headline='detailData.form !== undefined ? getValueForCurLanguage(detailData.form.form_title).value : "no title"'
    ></appheader>
    <div v-if='loading'>
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    <div v-else class="form-fields">
        <div
          v-for="field of tree"
          :key="field.form_field_id"
          class="form-field"
        >
          <form-field-group-view 
            v-if="field.field_type.type === 'group'"
            :field="field" 
            :formId='field.form_field_id'
            :submissionId="$route.params.id !== undefined ? $route.params.id : $route.params.subid"/>
          <form-field-view
            v-else-if='field.skip !== true'
            :class="'level0'"
            :field="field"
            :formId='field.form_field_id'
            :submissionId="$route.params.id !== undefined ? $route.params.id : $route.params.subid"
          />
        </div>
        <slot></slot>
    </div>
    <div class="button-container">
        <routerButton destination='/' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
    </div>
  </div>
 
</template>

<script>
import FormFieldGroupView from "@/components/FormFieldsView/FormFieldGroupRecView.vue";
import FormFieldView from "@/components/FormFieldsView/FormFieldView.vue";
import { mapGetters } from 'vuex';
export default {
  data(){
      return{
      }
  },
  components:{
    'form-field-group-view':FormFieldGroupView,
    'form-field-view':FormFieldView,
  },
  computed:{

    formId: function(){
        if(this.$route.params.formid !== undefined){
            return parseInt(this.$route.params.formid);
        }else{
            return -1;
        }
    },
    taskId: function(){
        if(this.$route.params.taskid !== undefined){
            this.$store.dispatch('getTask', parseInt(this.$route.params.taskid));
            return parseInt(this.$route.params.taskid);
        }else{
            return -1;
        }
    },
    isPersonal: function(){
        if(this.$route.params.isPersonal !== undefined){
            if(parseInt(this.$route.params.isPersonal) === 1){
                return true;
            }
        }
        return false;
    },
    detailData: function(){
        if(this.formId > -1 && this.taskId > -1){
            return this.getCheckResultByFormId(this.formId, this.taskId, this.isPersonal).docuDetails.submissions[0];
        }else if(this.$route.params.id !== undefined){
            return this.$store.state.local.documentation.tourDetails.submissions[this.$route.params.id];
        }else if(this.$route.params.subid !== undefined){
            var result = this.$store.state.local.documentation.tourDetails.submissions.filter(obj => {
                return obj.form_submission_id == this.$route.params.subid;
            })
            return result[0];
        }
        return null;
    },
    loading: function(){
        if(this.detailData !== undefined){
            return false;
        }else{
            return true;
        }
    },
    tree() {
        if(this.loading === false){
            return this.makeTree(this.detailData.form_fields,'form_field_id', 'parent_id', 'form_fields');
        }else{
            return null;
        }
    },
    ...mapGetters([
        'getValueForCurLanguage',
        'getCheckResultByFormId'
    ]),
  },
  methods:{
        getImgUrl(img) {
        return require('@/assets/'+img);
        },
        makeTree(array, idProp, parentProp, childProp) {
            const items = {}; // collect items here
            // add each items so we can look them up later
            const children = {};
            array.forEach((field) => {
                items[field[idProp]] = {...field,};
                if (field.field_type?.type === 'group' || field.type === 'group') {
                    items[field[idProp]][childProp] = [];
                }
                if (!children[field[parentProp]]) children[field[parentProp]] = [];
                    children[field[parentProp]].push(field);
            });

            const tree = [];
            array.forEach((field) => {
                // add each item with a parentId to the correct parent
                if (field[parentProp]) {
                    if (items[field[parentProp]]) {
                        items[field[parentProp]][childProp].push(items[field[idProp]]);
                    }
                } else {
                    tree.push(items[field[idProp]]); // if no parentId add it to the root
                }
            });

            const calcLevel = (parent, level) => {
                const items = children[parent] || [];
                return items.map((item) => {
                    const list = calcLevel(item[idProp], level + 1);
                    const cur = { level };
                    cur[childProp] = list;
                    return Object.assign({}, item, cur);
                });
            };
            return calcLevel(0, 0);
        },
    },
};

</script>

<style scoped lang='scss'>
@import "@/assets/scss/styles.scss";
.button-container{
    width:90%;
    margin:auto;
    margin-bottom:35px;
}

/** CSS loader **/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 45px;
}
.lds-ellipsis div {
  position: absolute;
  top: 12px;
  margin-left:5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $fontColorDark;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}

</style>
